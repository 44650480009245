import writeXlsxFile from 'write-excel-file'

export default {
	methods: {
		async exportPatient(patients) {
			const me = this
			const schema = [
				{
					column: 'Cama',
					type: String,
					value: patient => patient?.name ?? '',
				},
				{
					column: 'Paciente',
					type: String,
					value: patient => (patient.patient?.first_name ?? '') + ' ' + (patient.patient?.last_name ?? ''),
				},
				{
					column: 'Tipo de Documento',
					type: String,
					value: patient => patient.patient?.document_type?.long_name ?? '',
				},
				{
					column: 'Numero de Documento',
					type: String,
					value: patient => patient.patient?.document_number ?? '',
				},
				{
					column: 'Genero',
					type: String,
					value: patient => patient.patient?.gender?.long_name ?? '',
				},
				{
					column: 'EPS',
					type: String,
					value: patient => patient.patient?.hpc?.long_name ?? '',
				},
				{
					column: 'Riesgos',
					type: String,
					value: patient => patient.location_patient?.risks.map(item => item.name).join(', ') ?? '',
				},
				{
					column: 'Llamados Totales',
					type: String,
					value: patient => patient.patient?.hpc?.long_names ?? '0',
				},
				{
					column: 'Llamados Antendidos',
					type: String,
					value: patient => patient.patient?.hpc?.long_names ?? '0',
				},
				{
					column: 'Llamados No Antendidos',
					type: String,
					value: patient => patient.patient?.hpc?.long_names ?? '0',
				},
			]
			await writeXlsxFile(patients, {
				schema,
				fileName: 'file.xlsx',
			})
		},
		async exportCalls(patients) {
			console.log('data', patients)
			const me = this
			const schema = [
				{
					column: 'Paciente',
					type: String,
					value: d => d?.patient_name ?? '',
				},
				{
					column: 'Tipo de Documento',
					type: String,
					value: d => d?.patient_type_document ?? '',
				},
				{
					column: 'Numero de Documento',
					type: String,
					value: d => d?.patient_document ?? '',
				},
				{
					column: 'EPS',
					type: String,
					value: d => d?.patient_hpc ?? '',
				},
				{
					column: 'Genero',
					type: String,
					value: d => d?.patient_gender ?? '',
				},
				{
					column: 'Tipo de Dieta',
					type: String,
					value: d => d?.patient_type_diet ?? '',
				},
				{
					column: 'Tipo de Llamado',
					type: String,
					value: d => d?.call_type ?? '',
				},
				{
					column: 'Estado del Llamado',
					type: String,
					value: d => d?.call_status ?? '',
				},
				{
					column: 'Inicio del Llamado',
					type: String,
					value: d => d?.call_time_start ?? '',
				},
				{
					column: 'Duracion del Llamado',
					type: Number,
					value: d => d?.call_duration ?? 5,
				},
				{
					column: 'Cama',
					type: String,
					value: d => d?.location_name ?? '',
				},
				{
					column: 'Subarea',
					type: String,
					value: d => d?.subarea_name ?? '',
				},
				{
					column: 'Area',
					type: String,
					value: d => d?.area_name ?? '',
				},
				{
					column: 'Desayunos',
					type: String,
					value: patient => patient.patient.hpc.long_names ?? '0',
				},
			]
			await writeXlsxFile(patients, {
				schema,
				fileName: 'file.xlsx',
			})
		},
	},
}
